import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Impressum" />
        <h1>Impressum</h1>
        <p>Robert Krüger<br/>Gubener Str. 43<br/>10243 Berlin</p>
        <p>Telefon 0049 160 7831510<br/>E-Mail webmaster@vollbio.dev</p>
        
        <h2>Datenschutz</h2>
        <h3>Name und Kontakt des Verantwortlichen</h3>
        <p>Robert Krüger<br/>Gubener Str. 43<br/>10243 Berlin<br/>Telefon 0049 160 7831510<br/>E-Mail webmaster@vollbio.dev</p>
        
        <h3>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>
        <h4>Aufruf unserer Webseite</h4>
        <p>Bei der rein informatorischen Nutzung der Website werden automatisch Informationen an den Hoster übermittelt. Dies beinhaltet u.a. die IP-Adresse des genutzten Endgerätes, Datum und Uhrzeit der Anfrage, URL der aufgerufenen Seite und Inhalt der Anfrage. Die Webseite wird über Netlify (siehe Auftragsverarbeiter) bereitgestellt.</p>
        
        <h4>Bei der Nutzung von E-Mail Kontakt</h4>
        <p>Wenn du mich direkt per E-Mail kontaktierst, ist die Angabe einer gültigen E-Mail-Adresse erforderlich, um deine Anfrage beantworten zu können. Weitere von dir angegebene Kontaktmöglichkeiten und der Inhalt Ihrer Anfrage werden zur Bearbeitung der Anfrage und für eventuell gestellt Anschlussfragen gespeichert.</p>
        <p>Rechtsgrundlage ist das berechtige Interesse der Datenverarbeitung gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
      
        <h3>Rechte betroffener Personen</h3>
        <p>Werden personenbezogene Daten von dir verarbeitet stehen dir folgende Rechte zu. Du kannst von deinem Rechten Gebrauch machen indem du dich mit deinem Anliegen meldest.</p>
        <ul>
            <li>Auskunftsrecht: Du hast, gemäß Art. 15 DSGVO, das Recht unentgeltlich Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten, sowie eine Kopie dieser Daten, zu erhalten.</li>
            <li>Recht auf Berichtigung: Du hast, gemäß Art. 16 DSGVO, das Recht die Berichtigung zu über die zu Ihrer Person gespeicherten personenbezogenen unrichtigen oder unvollständiger Daten zu verlangen.</li>
            <li>Recht auf Löschung: Du hast, gemäß Art. 17 DSGVO, das Recht die Löschung der bei uns über Ihre Person gespeicherten personenbezogenen Daten zu verlangen, insofern diese nicht nicht zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich sind.</li>
            <li>Recht auf Einschränkung der Verarbeitung: Sind die Voraussetzungen gemäß Art. 18 erfüllt, haben Sie das Recht die Einschränkung er Verarbeitung der über Ihre Person gespeicherten Daten zu verlangen.</li>
            <li>Recht auf Datenübertragbarkeit: Du hast, gemäß Art. 20 DSGVO, das Recht die über Ihre Person gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.</li>
            <li>Widerspruchsrecht: Du hast das Recht, insofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden und insofern dafür Gründe vorlegen, die sich aus Ihrer besonderen Situation ergeben, gemäß Art.21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen.</li>
            <li>Widerrufsrecht: Beruht die Verarbeitung Ihrer personenbezogenen Daten auf einer erteilten Einwilligung, haben Sie jederzeit das Recht diese Einwilligung zu widerrufen.</li>
            <li>Recht auf Beschwerde: Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie das Recht sich bei einer Aufsichtsbehörde zu beschweren.</li>
        </ul>
        
        <h3>Auftragsverarbeiter</h3>
        <p>Netlify (Privacy Policy: https://www.netlify.com/privacy/)</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
